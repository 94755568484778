// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogdetails-js": () => import("./../src/pages/blogdetails.js" /* webpackChunkName: "component---src-pages-blogdetails-js" */),
  "component---src-pages-blogs-js": () => import("./../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-blogsform-js": () => import("./../src/pages/blogsform.js" /* webpackChunkName: "component---src-pages-blogsform-js" */),
  "component---src-pages-careertips-js": () => import("./../src/pages/careertips.js" /* webpackChunkName: "component---src-pages-careertips-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interviews-js": () => import("./../src/pages/interviews.js" /* webpackChunkName: "component---src-pages-interviews-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */)
}

